import { Config } from './config';
import router from '../router';
import store from '../store/index.js'
const Func={
	api_url:Config.api_url,
	home_url:Config.home_url,
	pic_url:Config.pic_url,
	check_rank(rule){
		var user_info=store.state.info;
		var res=user_info.rank.indexOf(rule);
		if(res===-1){
			return false;
		}else{
			return true;
		}
	},
	url_gen(get_data,fun){
		var user_info=store.state.info;
		if(!user_info || !user_info.id || !user_info.token){
	      router.push('/login');
	      return true;
	    }

		var url='';
		for(var i in get_data){
			url+=i+'='+get_data[i]+'&';
		}
		url=Config.api_url+'?'+url+'adm_id='+user_info.id+'&token='+user_info.token;
		fun(url);
	},
	url_get(get_data,fun){
		var user_info=store.state.info;
		if(!user_info || !user_info.id || !user_info.token){
	      router.push('/login');
	      return true;
	    }

		var url='';
		for(var i in get_data){
			url+=i+'='+get_data[i]+'&';
		}
		url=Config.api_url+'?'+url+'adm_id='+user_info.id+'&token='+user_info.token;

		fetch(url)
		.then(response=>response.json())
		.catch(error=>console.error('Error:',error))
		.then(response=>{
			if(response.error===301){
                router.push('/login');
            }else{
            	fun(response)
            }
        });
	},
	url_post(post_data,fun){
		var user_info=store.state.info;
		if(!user_info || !user_info.id || !user_info.token){
	      router.push('/login');
	      return true;
	    }

		var data='';
		for(var i in post_data){
			data+=i+'='+post_data[i]+'&';
		}
		data=data+'adm_id='+user_info.id+'&token='+user_info.token;

		fetch(Config.api_url,{
			method:'POST',
			body:data,
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
		.then(response=>response.json())
		.catch(error=>console.error('Error:',error))
		.then(response=>{
			if(response.error===301){
                router.push('/login');
            }
            fun(response)
        });
	},
	url_form(post_data,fun){
		var user_info=store.state.info;
		if(!user_info || !user_info.id || !user_info.token){
	      router.push('/login');
	      return true;
	    }
	    
		var data=new FormData();
		for(var i in post_data){
			data.append(i,post_data[i]);
		}
		data.append('token',user_info.token);
		data.append('adm_id',user_info.id);

		fetch(Config.api_url,{
			method:'POST',
			body:data
		})
		.then(response=>response.json())
		.catch(error=>console.error('Error:',error))
		.then(response=>{
			if(response.error===301){
                router.push('/login');
            }
            fun(response)
        });
	},
	url_login(post_data,fun){
		var data='';
		for(var i in post_data){
			data+=i+'='+post_data[i]+'&';
		}
		fetch(Config.api_url,{
			method:'POST',
			body:data,
			headers: new Headers({
				'Content-Type':'application/x-www-form-urlencoded'
			})
		})
		.then(response=>response.json())
		.catch(error=>console.error('Error:',error))
		.then(response=>{
			if(response.error===301){
                router.push('/login');
            }
            fun(response)
        });
	},
	objToArray(array) {
	    var arr = []
	    for (var i in array) {
	        arr.push(array[i]); 
	    }
	    return arr;
	}
}

export default Func