<template>
  <div v-if="open" class="alert alert-dismissible fade show" :class="[type=='1'?'alert-success':'alert-warning']" role="alert" v-show="tips">
    <strong>{{type=='1'?'操作成功':'操作失败'}}!</strong> {{this.tips}}.
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="open=false">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data(){
    return {
      tips:'',
      type:'1',
      open:false,
      timer:null
    }
  },
  mounted(){
    this.$PubSub.subscribe('message',(msg,data)=>{
      this.tips=data.tips;
      this.type=data.type;
      this.open=true;

      this.timer=setTimeout(()=>{
        this.open=false;
      },data.timeout||1500);
    })
  },
  beforeDestroy(){
    this.timer=null;
  }
}
</script>
<style lang="scss">
  .alert{
    width:300px;
    position:absolute;
    bottom:10px;
    left:10px;
  }
</style>
