import Vue from 'vue'
import Vuex from 'vuex'
Vue.use( Vuex )
var user_info=sessionStorage.getItem('user_info')||'{}';
console.log(JSON.parse(user_info));
const store = new Vuex.Store({
  state: {
    username: '',
    userId:'',
    info:JSON.parse(user_info)||{}
  },
  mutations: {
    setUserName( state, username ) {
      state.username = username
    },
    setInfo( state, info ) {
      state.info = info
    }
  },
  actions: {},
})
export default store
