import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PubSub from 'pubsub-js'
import Antd from 'ant-design-vue';
import moment from 'moment';
import Func from './common/func'
import * as echarts from 'echarts';
import 'moment/locale/zh-cn';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
Vue.prototype.$PubSub=PubSub;
Vue.prototype.$moment=moment;
Vue.prototype.$echarts=echarts;
Vue.prototype.$Func=Func;
Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
