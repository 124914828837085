<template>
  <a-locale-provider :locale="locale">
    <a-layout id="components-layout-demo-fixed">
      <a-layout-header :style="{ position: 'fixed', zIndex: 2, width: '100%' }">
        <a-row type="flex" justify="space-between">
          <a-col :span="5">
            <div class="logo">中商锂新能源</div>
          </a-col>
          <a-col :span="19">
            <a-row type="flex" jusify="space-between">
              <a-col :span="20">
                <a-menu
                  theme="dark"
                  mode="horizontal"
                  :selected-keys="[current]"
                  @click="handleClick"
                  :style="{ lineHeight: '64px' }"
                >
                  <a-menu-item v-for="val in Object.entries(menus)" :key="val[0]">
                    <router-link :to="val[1].path"><a-icon :type="val[1].icon" />{{val[1].name}}</router-link>
                  </a-menu-item>
                </a-menu>
              </a-col>
              <a-col :span="4" style="text-align:right;">
                  <!-- <a-avatar
                    :size="25"
                    :style="{ backgroundColor: '#fff',color:'#1890ff', verticalAlign: 'middle' }"
                  >
                    {{user_info.username.substr(0,1)}}
                  </a-avatar> -->
                  <a-dropdown style="margin-left:10px;">
                    <a class="ant-dropdown-link" @click="e => {e.preventDefault()}">{{user_info.username}}<a-icon type="down" /></a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a href="javascript:;" @click="logout">退出登录</a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-layout-header>
      <a-layout>
        <a-layout-sider v-if="left_menu.length>0" width="200" style="background: #fff;margin-top:70px;">
          <a-menu mode="inline" :default-selected-keys="[menu1_key]" @click="leftClick">
            <a-menu-item v-for="(val,key) in left_menu" :key="key">
              <router-link :to="val.path"><a-icon :type="val.icon" />{{val.name}}</router-link>
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
        <a-layout-content :style="{ padding: '0 10px', marginTop: '64px'}">
          <a-breadcrumb :style="{ margin: '16px 0' }">
            <a-breadcrumb-item><a-icon type="home" /> 首页</a-breadcrumb-item>
            <a-breadcrumb-item>{{menu}}</a-breadcrumb-item>
            <a-breadcrumb-item v-if="menu1">{{menu1}}</a-breadcrumb-item>
          </a-breadcrumb>
          <div :style="{ background: '#fff', padding: '24px', minHeight: '380px' }">
            <router-view class="m-3"/>
          </div>
        </a-layout-content>
      </a-layout>
      <a-layout-footer :style="{ textAlign: 'center' }">
        ©2022 中商锂新能源 版权所有
      </a-layout-footer>
      <Alert />
    </a-layout>
  </a-locale-provider>
</template>
<script>
import Alert from './components/Alert';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name:'App',
  components:{
    Alert
  },
  data(){
      return {
        user_timeout:null,
        locale: zhCN,
        current: 'Admin',
        menu:'',
        menu1:'',
        selectedKeys2: '1',
        collapsed: false,
        openKeys: 'sub1',
        left_menu:[],
        menu1_key:0,
        menus:{
          'Home':{
            path:'/home',
            icon:'database',
            name:'首页',
            sub:[]
          },
          'Order':{
            path:'/order',
            icon:'database',
            name:'询价',
            sub:[]
          },
          'Users':{
            path:'/users',
            icon:'team',
            name:'用户',
            sub:[]
          },
          'Account':{
            path:'/account',
            icon:'team',
            name:'银行帐号',
            sub:[]
          },
          'Branch':{
            path:'/branch',
            icon:'team',
            name:'网点',
            sub:[]
          },
          'Logs':{
            path:'/logs/index',
            icon:'team',
            name:'日志',
            sub:[
              {
                path:'/logs/index',
                icon:'table',
                name:'后台日志'
              },
              {
                path:'/logs/user',
                icon:'user',
                name:'用户日志'
              }
            ]
          },
          'Info':{
            path:'/info',
            icon:'team',
            name:'系统消息',
            sub:[]
          },
          'Admin':{
            path:'/setting/index',
            icon:'table',
            name:'系统设置',
            sub:[
              {
                path:'/setting/index',
                icon:'table',
                name:'系统设置'
              },
              {
                path:'/setting/admin',
                icon:'user',
                name:'管理员'
              },
              {
                path:'/setting/group',
                icon:'user',
                name:'管理员分组'
              },
              {
                path:'/setting/type',
                icon:'user',
                name:'电池类型'
              }
            ]
          },
        },
        user_info:{
          username:'admin'
        },
      }
  },
  watch:{
    $route:{
      immediate:true,
      handler(){
        var name=this.$router.history.current.name;
        var user_info=this.$store.state.info;
        if(name!=='Login' && (!user_info || !user_info.id || !user_info.token)){
          this.$router.push('/login');
          return true;
        }else{
          this.user_info=user_info;
          this.gen_menus();
        }
      }
    }
  },
  mounted(){
    this.user_timeout=setInterval(()=>{
      var name=this.$router.history.current.name;

      if(name && name!=='Login' && this.user_info.username!==''){
        var path=this.$router.history.current.path;
        var menus=this.menu_path(path);
        
        this.current=menus.current;
        this.menu=menus.menu;
        this.menu1=menus.menu1;
        this.menu1_key=menus.menu1_key;
        this.left_menu=this.menus[menus.current].sub;
        this.user_info=this.$store.state.info;
      }
    },200);
    this.gen_menus();
  },
  methods:{
    gen_menus(){
      var menus={
        'Home':{
          path:'/home',
          icon:'database',
          name:'首页',
          sub:[]
        },
        'Order':{
          path:'/order',
          icon:'database',
          name:'询价',
          sub:[]
        },
        'Users':{
          path:'/users',
          icon:'team',
          name:'用户',
          sub:[]
        },
        'Account':{
          path:'/account',
          icon:'team',
          name:'银行帐号',
          sub:[]
        },
        'Branch':{
          path:'/branch',
          icon:'team',
          name:'网点',
          sub:[]
        },
        'Logs':{
          path:'/logs/index',
          icon:'team',
          name:'日志',
          sub:[
            {
              path:'/logs/index',
              icon:'table',
              name:'后台日志'
            },
            {
              path:'/logs/user',
              icon:'user',
              name:'用户日志'
            }
          ]
        },
        'Info':{
          path:'/info',
          icon:'team',
          name:'系统消息',
          sub:[]
        },
        'Admin':{
          path:'/setting/index',
          icon:'table',
          name:'系统设置',
          sub:[
            {
              path:'/setting/index',
              icon:'table',
              name:'系统设置'
            },
            {
              path:'/setting/admin',
              icon:'user',
              name:'管理员'
            },
            {
              path:'/setting/group',
              icon:'user',
              name:'管理员分组'
            },
            {
              path:'/setting/type',
              icon:'user',
              name:'电池类型'
            }
          ]
        },
      };
      let rank=this.user_info.rank;
      let order=rank.indexOf('order');
      if(order===-1){
        delete menus.Order;
      }
      let user=rank.indexOf('user');
      if(user===-1){
        delete menus.Users;
      }
      let account=rank.indexOf('user_check');
      if(account===-1){
        delete menus.Account;
      }
      let branch=rank.indexOf('branch');
      if(branch===-1){
        delete menus.Branch;
      }
      let logs=rank.indexOf('logs');
      if(logs===-1){
        delete menus.Logs;
      }else{
        var logs_sub=[];
        let logs_list=rank.indexOf('logs_list');
        if(logs_list>-1){
          logs_sub.push(
            {
              path:'/logs/index',
              icon:'table',
              name:'后台日志'
            }
          );
        }
        let user_logs_list=rank.indexOf('user_logs_list');
        if(user_logs_list>-1){
          logs_sub.push(
            {
              path:'/logs/user',
              icon:'user',
              name:'用户日志'
            }
          );
        }

        menus.Logs.sub=logs_sub;
      }
      let info=rank.indexOf('info');
      if(info===-1){
        delete menus.Info;
      }
      let setting=rank.indexOf('setting');
      if(setting===-1){
        delete menus.Admin;
      }else{
        var admin_sub=[];
        let setting_index=rank.indexOf('setting_index');
        if(setting_index>-1){
          admin_sub.push(
            {
              path:'/setting/index',
              icon:'table',
              name:'系统设置'
            }
          );
        }
        let admin=rank.indexOf('admin');
        if(admin>-1){
          admin_sub.push(
            {
              path:'/setting/admin',
              icon:'user',
              name:'管理员'
            }
          );
        }
        let admingroup=rank.indexOf('admingroup');
        if(admingroup>-1){
          admin_sub.push(
            {
              path:'/setting/group',
              icon:'user',
              name:'管理员分组'
            }
          );
        }
        let type=rank.indexOf('type');
        if(type>-1){
          admin_sub.push(
            {
              path:'/setting/type',
              icon:'user',
              name:'电池类型'
            }
          );
        }

        menus.Admin.sub=admin_sub;
      }
      this.menus=menus;
    },
    menu_path(path){
      var list=Object.entries(this.menus);
      var current='';
      var menu='';
      var menu1='';
      var menu1_key=0;
      list.forEach((v)=>{
        if(v[1].path==path){
          current=v[0];
          menu=v[1].name;
          menu1='';
          menu1_key=0;
        }
        v[1].sub.forEach((vv,kk)=>{
          if(vv.path==path){
            current=v[0];
            menu=v[1].name;
            menu1=vv.name;
            menu1_key=kk;
          }
        })
      })
      return {
        current,
        menu,
        menu1,
        menu1_key
      };
    },
    handleClick(e) {
      var current = e.key;
      this.current=current;
      this.menu=this.menus[current].name;
      this.left_menu=this.menus[current].sub;
    },
    leftClick(e) {
      this.menu1=this.left_menu[e.key].name;
    },
    logout(){
      sessionStorage.setItem('user_info',JSON.stringify({username:''}));
      this.$store.commit('setInfo', {username:''});
      this.$router.push('/login');
    }
  }
}
</script>
<style lang="scss">
#components-layout-demo-fixed .logo {
  margin-right:24px;
  float: left;
  font-size:26px;
  color:#fff;
}
</style>